import IndexNavbar from "components/Navbars/IndexNavbar";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Row, Col} from "reactstrap";

function OurPurpose() {
    const [loading, setLoading] = useState(true);

    const handleLoading = () => {
        setLoading(false);
    }

    useEffect(() => {
        window.addEventListener("load", handleLoading);
        return () => window.removeEventListener("load", handleLoading);
    }, [])

    return (
        <>
            {loading && loading ?
                <div className="loadingPage">
                    <div className="spinner"></div>
                </div>
                :
                <>
                    <IndexNavbar />
                    <div className="vh-100 w-100 overflow-hidden position-fixed">
                        <video autoPlay loop playsInline muted preload="auto" className="w-100 h-100" style={{ objectFit: "cover", objectPosition: "center" }}>
                            <source type="video/mp4" src={window.location.origin + "/starBg.mp4"} />
                            Your browser does not support HTML5 video.
                        </video>
                    </div>

                    <div className="purpose">
                        <div className="header-section pt-5 pt-lg-0">
                            <Row className="justify-content-center align-items-center min-height-100 mx-0 pt-5 pt-lg-0">
                                <Col lg={5} md={8} sm={8} xs={8} className="text-center mb-4">
                                    <img src={require("../assets/img/home/Run.gif").default} alt="purpose-robot" className="mx-auto headerGif" />
                                </Col>
                                <Col lg={6} md={11} sm={11} xs={11}>
                                    <div className="header-title-section">
                                        <div className="small-square mr-4 mr-lg-5" />
                                        <div className="header-title-text">
                                            <span>Our Purpose</span>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <span className="header-desc d-block mb-4">
                                            We are a leading game development and art creation company, offering comprehensive services that encompass every aspect of game creation. Our team consists of experienced game artists and developers who excel in following the latest industry trends and best practices.
                                        </span>
                                        <span className="header-desc d-block">
                                            Whether you are a world-renowned gaming company or an indie studio, we are here to cater to your specific needs. From concept to completion, we specialize in delivering tailor-made game development solutions across various genres and major platforms.
                                        </span>
                                    </div>
                                    <div className="pt-5 mt-5 text-center">
                                        <img src={require("../assets/img/purpose/header-deco.webp").default} alt="" className="mx-auto decoSize" />
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className="stepSection">
                            <Row className="justify-content-center align-items-center min-height-100 mx-0">
                                <Col lg={4} md={9} sm={11} xs={11} className="text-left mb-5">
                                    <div className="position-relative text-center">
                                        <img src={require("../assets/img/purpose/partnerBg.webp").default} alt="" className="mx-auto partnerArrow" />
                                        <div className="partnerHeaderDiv">
                                            <span className="partnerSectionTitle d-block mb-4">Partnering with you to reach your goals.</span>
                                            <img src={require("../assets/img/purpose/partnerCross.webp").default} alt="" className="mx-auto partnerCrossSize" />
                                        </div>
                                    </div>
                                </Col>
                                <Col lg={7} md={11} sm={11} xs={11} className="text-left mb-4">
                                    <Row className="justify-content-center align-items-start">
                                        <Col lg={4} md={6} sm={12} xs={12} className="text-center text-lg-left mb-3">
                                            <div className="">
                                                <img src={require("../assets/img/purpose/step1.webp").default} alt="" className="stepImg" />
                                                <div className="mt-3">
                                                    <span className="stepNumber d-block mb-2">Step 1</span>
                                                    <span className="stepTitle d-block mb-2">Consultation</span>
                                                    <span className="stepDivider d-block mb-4">.............</span>
                                                    <span className="stepDesc d-block mb-2">As a leading firm, we prioritize delivering quality and value to our clients. Our team excels in idea validation and brainstorming, ensuring your concepts are on the right track.</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4} md={6} sm={12} xs={12} className="text-center text-lg-left mb-3">
                                            <div className="">
                                                <img src={require("../assets/img/purpose/step2.webp").default} alt="" className="stepImg" />
                                                <div className="mt-3">
                                                    <span className="stepNumber d-block mb-2">Step 2</span>
                                                    <span className="stepTitle d-block mb-2">INDIVIDUAL APPROACH</span>
                                                    <span className="stepDivider d-block mb-4">.............</span>
                                                    <span className="stepDesc d-block mb-2">We follow an agile development approach, from inception to project finalization. This guarantees transparency, cost and time effectiveness, flexibility, and stringent quality control.</span>
                                                </div>
                                            </div>
                                        </Col>
                                        <Col lg={4} md={6} sm={12} xs={12} className="text-center text-lg-left mb-3">
                                            <div className="">
                                                <img src={require("../assets/img/purpose/step3.webp").default} alt="" className="stepImg" />
                                                <div className="mt-3">
                                                    <span className="stepNumber d-block mb-2">Step 3</span>
                                                    <span className="stepTitle d-block mb-2">AGILE DEVELOPMENT</span>
                                                    <span className="stepDivider d-block mb-4">.............</span>
                                                    <span className="stepDesc d-block mb-2">We don't believe in one-size-fits-all solutions. Your unique needs and market conditions matter to us. Our team stays updated with the latest trends to provide tailored solutions that work for you.</span>
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </div>

                        <div className="diversitySection min-height-100 pt-5">
                            <div className="position-relative text-center pt-5">
                                <span className="partnerSectionTitle d-block">Embracing diversity,</span>
                                <span className="partnerSectionTitle d-block">delivering tailored solutions.</span>

                                <Row className="justify-content-center align-items-center mt-5 pt-5 mx-0">
                                    <Col lg={9} md={11} sm={11} xs={11} className="">
                                        <Row className="justify-content-center align-items-center">
                                            <Col lg={4} md={6} sm={12} xs={12} className="mb-4">
                                                <div className="diversityDiv">
                                                    <div>
                                                        <img src={require("../assets/img/purpose/diversity1.webp").default} alt="" className="stepImg" />
                                                        <div className="mt-3">
                                                            <span className="diversityTitle d-block mb-2">2D Game Art</span>
                                                            <span className="stepDivider d-block mb-4">.............</span>
                                                            <span className="stepDesc d-block mb-2">We offer joyful 2D character design and gloomy castle concept art.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} xs={12} className="mb-4">
                                                <div className="diversityDiv">
                                                    <div>
                                                        <img src={require("../assets/img/purpose/diversity2.webp").default} alt="" className="stepImg" />
                                                        <div className="mt-3">
                                                            <span className="diversityTitle d-block mb-2">3D Game Art</span>
                                                            <span className="stepDivider d-block mb-4">.............</span>
                                                            <span className="stepDesc d-block mb-2">Experience game art at its finest with our talented 3D artists.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} xs={12} className="mb-4">
                                                <div className="diversityDiv">
                                                    <div>
                                                        <img src={require("../assets/img/purpose/diversity3.webp").default} alt="" className="stepImg" />
                                                        <div className="mt-3">
                                                            <span className="diversityTitle d-block mb-2">Prototyping</span>
                                                            <span className="stepDivider d-block mb-4">.............</span>
                                                            <span className="stepDesc d-block mb-2">Our team specializes in creating captivating visuals and animations for games.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col lg={4} md={6} sm={12} xs={12} className="mb-4">
                                                <div className="diversityDiv">
                                                    <div>
                                                        <img src={require("../assets/img/purpose/diversity4.webp").default} alt="" className="stepImg" />
                                                        <div className="mt-3">
                                                            <span className="diversityTitle d-block mb-2">Game Development</span>
                                                            <span className="stepDivider d-block mb-4">.............</span>
                                                            <span className="stepDesc d-block mb-2">Comprehensive game development services for all platforms.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={4} md={6} sm={12} xs={12} className="mb-4">
                                                <div className="diversityDiv">
                                                    <div>
                                                        <img src={require("../assets/img/purpose/diversity5.webp").default} alt="" className="stepImg" />
                                                        <div className="mt-3">
                                                            <span className="diversityTitle d-block mb-2">Animations</span>
                                                            <span className="stepDivider d-block mb-4">.............</span>
                                                            <span className="stepDesc d-block mb-2">Captivate your audience with our polished animations for characters and environments.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col lg={4} md={6} sm={12} xs={12} className="mb-4">
                                                <div className="diversityDiv">
                                                    <div>
                                                        <img src={require("../assets/img/purpose/diversity6.webp").default} alt="" className="stepImg" />
                                                        <div className="mt-3">
                                                            <span className="diversityTitle d-block mb-2">AR & VR Development</span>
                                                            <span className="stepDivider d-block mb-4">.............</span>
                                                            <span className="stepDesc d-block mb-2">Solid hands-on experience in creating captivating Immersive Reality apps.</span>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>

                                <div className="pt-5 mt-5 text-center">
                                    <img src={require("../assets/img/purpose/header-deco.webp").default} alt="" className="mx-auto decoSize" />
                                </div>

                                <div className="text-center pb-5" style={{ paddingTop: "10vh" }}>
                                    <img src={require("../assets/img/home/Robot-gif.gif").default} alt="" className="mb-4 robotGifSize" />
                                    <span className="text-white d-block copywriteText">2023 Autopilot Studio. All rights reserved.</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default OurPurpose;
