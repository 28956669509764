import IndexNavbar from "components/Navbars/IndexNavbar";
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Row, Col, Input } from "reactstrap";

function ReachUs() {

    const [loading, setLoading] = useState(true);

    const handleLoading = () => {
        setLoading(false);
    }

    useEffect(() => {
        window.addEventListener("load", handleLoading);
        return () => window.removeEventListener("load", handleLoading);
    }, [])

    return (
        <>
            {loading && loading ?
                <div className="loadingPage">
                    <div className="spinner"></div>
                </div>
                :
                <>
                    <IndexNavbar />
                    <div className="vh-100 w-100 overflow-hidden position-fixed">
                        <video autoPlay loop playsInline muted preload="auto" className="w-100 h-100" style={{ objectFit: "cover", objectPosition: "center" }}>
                            <source type="video/mp4" src={window.location.origin + "/starBg.mp4"} />
                            Your browser does not support HTML5 video.
                        </video>
                    </div>

                    <div className="contactUs w-100">
                        <div className="header-section pt-5 pt-lg-0">
                            <Row className="justify-content-center align-items-center mx-0 pt-5 pt-lg-0 mx-0 w-100">
                                <Col lg={5} md={6} sm={10} xs={10} className="text-center mb-4">
                                    <img src={require("../assets/img/home/phone.gif").default} alt="purpose-robot" className="mx-auto iconGif" />
                                </Col>
                                <Col lg={5} md={11} sm={11} xs={11}>
                                    <div className="header-title-section">
                                        <div className="small-square mr-4 mr-lg-5" />
                                        <div className="header-title-text">
                                            <span>Reach out to US</span>
                                        </div>
                                    </div>
                                    <div className="mt-5">
                                        <span className="header-small-title d-block mb-4">
                                            Autopilot is open to everyone! Stay informed and get in touch with our team.
                                        </span>
                                    </div>
                                </Col>
                                {/* <Col lg={12} md={12} sm={12} xs={12} className="text-center">
                            <img src={require("../assets/img/purpose/header-deco.webp").default} alt="" className="mx-auto" />
                        </Col> */}
                            </Row>
                        </div>

                        <div className="reachUsSection">
                            <Row className="justify-content-center align-items-center min-height-100 mx-0">
                                <Col lg={9} md={11} sm={12} xs={12} className="">
                                    <Row className="justify-content-center align-items-start inputBoxBg mx-0">
                                        <Col lg={12} md={12} sm={12} xs={12} className="px-0">
                                            <div className="border-bottom-white text-center headerPadding">
                                                <span className="inputBoxTitle">Let's discuss your project!</span>
                                            </div>
                                        </Col>

                                        <Col lg={5} md={11} sm={12} xs={12} className="mt-5 mb-5">
                                            <Input
                                                placeholder="Your Name"
                                                type="text"
                                                className="contactUs-textBox"
                                            />
                                        </Col>
                                        <Col lg={5} md={11} sm={12} xs={12} className="mt-0 mt-lg-5 mb-5">
                                            <Input
                                                placeholder="Your Email"
                                                type="text"
                                                className="contactUs-textBox"
                                            />
                                        </Col>
                                        <Col lg={10} md={11} sm={12} xs={12} className="mb-5 pb-5">
                                            <Input
                                                placeholder="Message"
                                                type="textarea"
                                                rows="10"
                                                name="message"
                                                className="contactUs-textArea"
                                            />
                                        </Col>
                                    </Row>
                                    <div className="w-100 sentBtn text-center px-0">
                                        <span className="">Sent</span>
                                    </div>
                                </Col>
                            </Row>
                        </div>

                        <div className="text-center pb-5" style={{ paddingTop: "10vh" }}>
                            <img src={require("../assets/img/home/Robot-gif.gif").default} alt="" className="mb-4 robotGifSize" />
                            <span className="text-white d-block copywriteText">2023 Autopilot Studio. All rights reserved.</span>
                        </div>
                    </div>
                </>
            }
        </>
    );
}

export default ReachUs;
